import React from 'react'

import SEO from '../../components/seo'
import { Container, Row, Column } from '../../components/ui/layout/Grid'
import { ContentBox } from '../../components/ui/layout/ContentBox'
import { Tick } from '../../components/ui/Icons'

const OrderSuccess = () =>
  <>
    <SEO title="Thank you for your order!" />
    <Container size="md">
      <Row
        css={`
          min-height: 65vh;
        `}
      >
        <Column>
          <ContentBox
            css={`
              text-align:center;
              svg {
                width: 100px;
                height: 100px;
              }
            `}
          >
            <Tick />
            <h1>Thank you for your order!</h1>
            <p>You will receive an email from the team in a short time. Got any questions? We would like to hear from you at hello@investabit.com</p>
          </ContentBox>
        </Column>
      </Row>
    </Container>
  </>

export default OrderSuccess
