import React from 'react'
import PropTypes from 'prop-types'

const Info = () =>
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M16.2123 19.853L15.9485 20.9316C15.1563 21.2444 14.5242 21.4819 14.0529 21.6456C13.5815 21.81 13.0334 21.8911 12.4094 21.8911C11.4506 21.8911 10.7052 21.6565 10.1732 21.1903C9.64121 20.7219 9.37521 20.1285 9.37521 19.4087C9.37521 19.1295 9.39494 18.8431 9.43514 18.5508C9.47533 18.2585 9.53964 17.9296 9.62733 17.5613L10.6175 14.0587C10.7052 13.7233 10.7805 13.4054 10.8404 13.1051C10.9011 12.8062 10.9303 12.5314 10.9303 12.2837C10.9303 11.8365 10.8375 11.5237 10.6533 11.3476C10.4692 11.1722 10.1169 11.083 9.59444 11.083C9.33868 11.083 9.0756 11.124 8.80814 11.2036C8.53848 11.2833 8.30829 11.36 8.11537 11.4316L8.37991 10.3515C9.0281 10.0877 9.64779 9.86192 10.2404 9.67412C10.8338 9.48558 11.3936 9.39204 11.9234 9.39204C12.8756 9.39204 13.61 9.6215 14.1259 10.0819C14.6419 10.5423 14.8998 11.1393 14.8998 11.8752C14.8998 12.0272 14.883 12.2954 14.8465 12.679C14.8107 13.0634 14.7449 13.4149 14.6484 13.735L13.6626 17.2237C13.5823 17.5043 13.5092 17.8244 13.4463 18.1847C13.3813 18.5427 13.3499 18.8168 13.3499 19.0002C13.3499 19.4635 13.4529 19.7799 13.6604 19.948C13.8687 20.1161 14.2283 20.1994 14.7383 20.1994C14.978 20.1994 15.2506 20.157 15.5531 20.0737C15.8557 19.9904 16.0756 19.9166 16.2123 19.853V19.853ZM16.4622 5.20619C16.4622 5.81419 16.2335 6.33304 15.7731 6.75981C15.3134 7.18804 14.7603 7.40288 14.1128 7.40288C13.4639 7.40288 12.9092 7.18804 12.4444 6.75981C11.9811 6.33304 11.7488 5.81419 11.7488 5.20619C11.7488 4.59965 11.9811 4.07935 12.4444 3.64746C12.9085 3.21558 13.4639 3 14.1128 3C14.7603 3 15.3134 3.21631 15.7731 3.64746C16.2335 4.07935 16.4622 4.59965 16.4622 5.20619V5.20619Z"
    />
  </svg>

const Tick = ({ strokeColor }) =>
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <circle
      cx="12"
      cy="12"
      r="9"
      stroke={strokeColor || '#00E6BE'}
      strokeWidth="2"
    />
    <path
      d="M8.70711 12.2929C8.31658 11.9024 7.68342 11.9024 7.29289 12.2929C6.90237 12.6834 6.90237 13.3166 7.29289 13.7071L8.70711 12.2929ZM10.5 15.5L9.79289 16.2071C9.98533 16.3995 10.2479 16.5052 10.52 16.4998C10.7921 16.4944 11.0502 16.3783 11.2348 16.1783L10.5 15.5ZM17.2348 9.67828C17.6094 9.27246 17.5841 8.6398 17.1783 8.2652C16.7725 7.89059 16.1398 7.9159 15.7652 8.32172L17.2348 9.67828ZM7.29289 13.7071L9.79289 16.2071L11.2071 14.7929L8.70711 12.2929L7.29289 13.7071ZM11.2348 16.1783L17.2348 9.67828L15.7652 8.32172L9.7652 14.8217L11.2348 16.1783Z"
      fill={strokeColor || '#00E6BE'}
    />
  </svg>

const Cross = ({ strokeColor }) =>
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <circle
      cx="12"
      cy="12"
      r="9"
      stroke={strokeColor || '#E15C7E'}
      strokeWidth="2"
    />
    <path
      d="M9 9L15 15"
      stroke={strokeColor || '#E15C7E'}
      strokeWidth="2"
      strokeLinecap="round"
    />
    <path
      d="M9 15L15 9"
      stroke={strokeColor || '#E15C7E'}
      strokeWidth="2"
      strokeLinecap="round"
    />
  </svg>

const Loader = ({ bgColor }) => {
  // By Sam Herbert (@sherb), for everyone. More @ http://goo.gl/7AJzbL
  return (
    <svg
      width="38"
      height="38"
      viewBox="0 0 38 38"
      xmlns="http://www.w3.org/2000/svg"
      fill="#000"
    >
      <defs>
        <linearGradient
          x1="8.042%"
          y1="0%"
          x2="65.682%"
          y2="23.865%"
          id="a"
        >
          <stop
            stopColor={bgColor || 'red'}
            stopOpacity="0"
            offset="0%"
          />
          <stop
            stopColor={bgColor || 'red'}
            stopOpacity=".631"
            offset="63.146%"
          />
          <stop
            stopColor={bgColor || 'red'}
            offset="100%"
          />
        </linearGradient>
      </defs>
      <g
        fill="none"
        fillRule="evenodd"
      >
        <g transform="translate(1 1)">
          <path
            d="M36 18c0-9.94-8.06-18-18-18"
            id="Oval-2"
            stroke="url(#a)"
            strokeWidth="2"
          >
            <animateTransform
              attributeName="transform"
              type="rotate"
              from="0 18 18"
              to="360 18 18"
              dur="0.9s"
              repeatCount="indefinite"
            />
          </path>
          <circle
            fill={bgColor || 'red'}
            cx="36"
            cy="18"
            r="1"
          >
            <animateTransform
              attributeName="transform"
              type="rotate"
              from="0 18 18"
              to="360 18 18"
              dur="0.9s"
              repeatCount="indefinite"
            />
          </circle>
        </g>
      </g>
    </svg>
  )
}

export {
  Info,
  Tick,
  Cross,
  Loader
}
